<template>
  <div class="main">
    <div class="backPage" @click="$router.go(-1)">返回</div>
    <heand :tagnum="tagnum"></heand>
    <div class="chuangx_ziytop">
      <!-- 创新 -->
      <dl>
        <dt>{{currentData.name}}</dt>
      </dl>
    </div>

    <div class="chuangx_fffbox">
      <h1 class="chuang_tit">简介</h1>
      <div class="chuangx_jsh">
        <p>{{currentData.introduct}}</p>
        <div class="shy_add">实验室地址：{{currentData.address}}</div>
      </div>
    </div>

    <div class="chuangx_fffbox">
      <h1 class="chuang_tit">地图</h1>
      <div class="chuangx_map">
        <baidu-map
          class="bm-view"
          :center="currentData.jwd"
          :zoom="zoom"
          :double-click-zoom="double"
          :dragging="dragging"
          :scroll-wheel-zoom="true"
        >
          <bm-marker :position="center" :dragging="false"></bm-marker>
        </baidu-map>
      </div>
    </div>
  </div>
</template>

<script>
import { catongid } from "@/api/index";
export default {
  name: "chdetsils1",
  data() {
    return {
      currentData: {},
      tagnum: 2,
      id: "1",
      zoom: 15,
      double: false,
      dragging: false,
      details: [],
      center: { lat: "", lng: "" }
    };
  },
  created() {
    console.log(999)
    this.id = this.$route.query.id;
    this.cateone = this.$route.query.cateone;
    this.getData();
  },
  methods: {
    async getData(inid, cate) {
      const res = await catongid({
        id: Number(this.id),
        cateone: Number(this.cateone)
      });
      this.center.lat = res.jwd.lat;
      this.center.lng = res.jwd.lng;
      console.log(res);
      this.currentData = res;
    }
  }
};
</script>

<style lang="less" scoped>
.main {
  background: #f2f4f7;
  overflow: hidden;
  zoom: 1;
}
.chuangx_ziytop {
  margin: 20px auto;
  background: url(../../assets/industrial/chuangx_top4.png) no-repeat;
  width: 1360px;
  height: 200px;
  padding: 40px;
  color: #fff;

  dl {
    overflow: hidden;
    zoom: 1;
    dt {
      font-size: 34px;
      font-weight: bold;
      float: left;
      line-height: 120px;
    }
  }
}

.chuangx_fffbox {
  width: 1360px;
  overflow: hidden;
  zoom: 1;
  background: #fff;
  text-align: left;
  margin: 0 auto 20px auto;
  padding-bottom: 20px;

  .chuang_tit {
    font-size: 18px;
    color: #333;
    padding: 16px;
  }
  .chuangx_jsh {
    font-size: 15px;
    line-height: 26px;
    padding: 0 16px;
    p {
      text-indent: 24px;
    }
    .shy_add {
      font-weight: bold;
      color: #fe6a00;
      text-indent: 24px;
      margin: 20px 0 20px 0;
    }
  }
  .chuangx_map {
    padding: 0 16px;
    height: 300px;
    .bm-view {
      width: 100%;
      height: 100%;
    }
  }
}
</style>